.student-header-container {
	background-color: #53c39a;
	padding: 10px;
}
.student-header-container .right-side-buttons {
	text-align: right;
}
.student-header-container .action-button {
	display: inline-block;
	padding: 0.5rem 1rem;
	color: #fff;
}

.student-header-container .action-button:hover {
	cursor: pointer;
	background-color: #357a61;
	-webkit-transition: background-color 300ms linear;
	-ms-transition: background-color 300ms linear;
	transition: background-color 300ms linear;
}

.student-header-student-name {
	font-size: 25px;
	font-family: lemon_milkbold;
}

.student-header-profile-image-wrapper {
	display: inline-block;
}
