.rfid-scan-page {
	text-align: center;
	background: #70b447;
	background: -webkit-linear-gradient(153deg, #70b447 0%, #cbda4d 100%);
	background: linear-gradient(153deg, #70b447 0%, #cbda4d 100%);
	min-width: 100vw;
	min-height: 100vh;
}

.date-container {
	font-size: 1.75rem;
	font-weight: 500;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.image-container {
	max-width: 200px;
	border-radius: 1rem;
	overflow: hidden;
	/* border: 1px solid var(--color5); */
	display: inline-block;
}

.image-container img {
	width: 100%;
	height: 100%;
}

.details-group {
	margin-top: 0.5rem;
	font-weight: 600;
	font-size: 1.5rem;
}

.details-group.section {
	font-size: 1rem;
	font-weight: 500;
}

.details-group .details-label {
	font-size: 1rem;
	margin-bottom: 0.5rem;
}

.log-container {
	margin-top: 1rem;
	font-style: italic;
	font-weight: 500;
}

.error-container {
	margin-top: 1rem;
	padding: 0 2rem;
}
