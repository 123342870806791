.login-page {
	background: #70b447;
	background: -webkit-linear-gradient(153deg, #70b447 0%, #cbda4d 100%);
	background: linear-gradient(153deg, #70b447 0%, #cbda4d 100%);
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
}

.form-container {
	width: 100%;
	max-width: 650px;
	margin: auto;
}

.form-field {
	margin-bottom: 2rem;
}
